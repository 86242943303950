const styles = {
  container: {
    marginBottom: '.5em',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    padding: '1px 0',
  },
  backButton: {
    '&&': {
      marginRight: '.5em',
    },
  },
};

export default styles;
