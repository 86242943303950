import React, { useState } from 'react';
import { Menu, MenuItem, Icon, ListItemIcon } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import Button from 'components/Button';
import styles from './styles';

const ButtonMenu = ({ options = [], selection = [], buttonProps = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const renderItem = () =>
    options
      ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      ?.map(
        ({ action, icon, name, show = true }, index) =>
          show && (
            <MenuItem
              key={index?.toString()}
              disabled={!anchorEl}
              onClick={() => {
                setAnchorEl(null);
                if (action) action(selection);
              }}
            >
              {icon && (
                <ListItemIcon>
                  <Icon>{icon}</Icon>
                </ListItemIcon>
              )}
              {name}
            </MenuItem>
          )
      );

  if (!!options?.length) {
    return (
      <>
        <Button
          {...buttonProps}
          size="small"
          aria-label="Opções"
          aria-haspopup="true"
          aria-controls={!!anchorEl && 'basic-menu'}
          aria-expanded={!!anchorEl && 'true'}
          startIcon={<MoreHoriz fontSize="medium" />}
          sx={styles.button}
          onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
        >
          Opções
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          {renderItem()}
        </Menu>
      </>
    );
  }
  return null;
};

export default ButtonMenu;
