const styles = {
  container: {
    padding: '15px',
  },
  title: {
    '&&': {
      margin: '0 0 1em 0',
    },
  },
};

export default styles;
