import { toast } from 'react-toastify';

export const toastSuccess = (res) => {
  const message = res?.data?.message;
  return toast.success(message);
};

export const toastError = (error) => {
  const message = error?.response?.data?.message || error?.message;
  return toast.error(message);
};

export const toastWarning = (message) => {
  return toast.warn(message);
};

export const toastNotify = (message) => {
  return toast(message, { hideProgressBar: true });
};
