import { GridActionsCellItem } from '@mui/x-data-grid';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

const grid = ({ onMove }) => {
  return [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Ordem',
      flex: 1,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<KeyboardArrowUp />}
            label="Mover para cima"
            onClick={() => onMove(params, 'up')}
          />,
          <GridActionsCellItem
            icon={<KeyboardArrowDown />}
            label="Mover para baixo"
            onClick={() => onMove(params, 'down')}
          />,
        ];
      },
    },
    {
      field: 'headerName',
      headerName: 'Título',
      flex: 2,
      sortable: false,
    },
    {
      field: 'width',
      headerName: 'Tamanho',
      flex: 1,
      type: 'number',
      editable: true,
      sortable: false,
    },
    {
      field: 'visible',
      headerName: 'Visível',
      flex: 1,
      type: 'boolean',
      editable: true,
      sortable: false,
    },
    {
      field: 'filterable',
      headerName: 'Filtro',
      flex: 1,
      type: 'boolean',
      editable: true,
      sortable: false,
    },
  ];
};

export default grid;
