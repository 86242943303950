const styles = {
  container: {
    backgroundColor: 'white',
    height: '100vh',
    display: 'flow-root',
  },
  insideContainer: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: '80vw',
    maxWidth: '680px',
    alignSelf: 'center',
    padding: '10px',
  },
  img: {
    objectFit: 'contain',
  },
};

export default styles;
