import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import styles from './styles';

const Input = forwardRef(({ control, name, ...props }, ref) => {
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            {...field}
            {...props}
            name={name}
            autoComplete="off"
            variant="outlined"
            FormHelperTextProps={{ sx: styles.helperText }}
            sx={styles.textField}
            size="small"
          />
        )}
      />
    );
  }

  return (
    <TextField
      fullWidth
      {...props}
      autoComplete="off"
      variant="outlined"
      FormHelperTextProps={{ sx: styles.helperText }}
      sx={styles.textField}
      size="small"
    />
  );
});

export default Input;
