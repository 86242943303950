import React from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import Button from 'components/Button';
import logo from 'assets/logo.png';
import styles from './styles';

const Error = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <div style={styles.insideContainer}>
        <Paper sx={styles.paper} elevation={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item textAlign="center">
              <img src={logo} style={styles.img} alt="Logo" />
            </Grid>
            <Grid item textAlign="center">
              <Typography variant="h6">Rota não encontrada</Typography>
            </Grid>
            <Grid item textAlign="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate('/app')}
              >
                VOLTAR PARA O INÍCIO
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default Error;
