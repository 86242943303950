import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { toastNotify } from 'utils/toast';
import api from 'services/api';
import socket from 'utils/socket';

export const NotificaçõesContext = createContext();

export const NotificaçõesProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [notifications, setNotifications] = useState([]);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  useEffect(() => {
    if (user) {
      socket.emit('joinNotifications', {
        sender: user?.documento + '_' + user?.id,
      });
    }
  }, [user]);

  useEffect(() => {
    if (socket) {
      socket.on('receiveNotifications', (note) => {
        toastNotify(note?.notificacao);
        setNotifications((prev) => [note, ...prev]);
      });
    }
  }, [socket]);

  const getNotifications = async () => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Notificacao`);
      setNotifications(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postNotifications = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Notificacao', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <NotificaçõesContext.Provider
      value={{
        getLoading,
        postLoading,
        notifications,
        getNotifications,
        postNotifications,
      }}
    >
      {children}
    </NotificaçõesContext.Provider>
  );
};
