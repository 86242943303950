const styles = {
  cardContainer: {
    padding: '.5em',
  },
  dotContainer: {
    alignItems: 'center',
  },
  dot: (n) => ({
    pr: '1em',
    fontSize: 12,
    color: n?.finalizado === 'NAO' ? 'primary.main' : 'transparent',
  }),
};

export default styles;
