export const joinColumns = (columns = [], complement = []) => {
  return columns?.map((col) => {
    const comp = complement?.find((comp) => comp?.field === col?.field);
    if (comp) {
      return { ...comp, ...col };
    } else {
      return col;
    }
  });
};

export const getActiveFilter = (values = {}) => {
  let badge = 0;

  Object.keys(values).map((key) => {
    if (
      values[key] !== 'like' &&
      Boolean(values[key]) &&
      ((Array.isArray(values[key]) && values[key]?.length) ||
        (typeof values[key] === 'object' &&
          (Boolean(values?.[key]?.i) || Boolean(values?.[key]?.f))) ||
        typeof values[key] === 'string' ||
        typeof values[key] === 'number')
    ) {
      badge++;
    }
  });

  return badge;
};
