import { createContext, useState } from 'react';
import api from 'services/api';

export const GridContext = createContext();

export const GridProvider = ({ children }) => {
  const [configLoading, setConfigLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [resumo, setResumo] = useState({
    data: [],
    colunas: [],
    page: 0,
    size: 10,
  });

  const configGrid = async ({ rotina, colunas, cb }) => {
    try {
      setConfigLoading(true);
      if (!!colunas) {
        await api.post('/Config', { rotina, colunas });
      } else {
        await api.delete('/Config', { params: { rotina } });
      }
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setConfigLoading(false);
    }
  };

  const deleteGrid = async ({ params, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete('/Grid', { params });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const exportPdf = async ({ cb, ...payload }) => {
    try {
      const { data } = await api.post('/Grid/PDF', payload, {
        responseType: 'blob',
      });
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      if (cb) {
        cb();
      }
    } catch (error) {}
  };

  const exportXls = async ({ cb, ...payload }) => {
    try {
      const { data } = await api.post('/Grid/XLS', payload, {
        responseType: 'blob',
      });
      const file = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      if (cb) {
        cb();
      }
    } catch (error) {}
  };

  const getResumo = async (payload) => {
    try {
      setGetLoading(true);
      const { data } = await api.post('/Grid', {
        filter: resumo?.filter,
        page: resumo?.page,
        size: resumo?.size,
        order: resumo?.order,
        ...payload,
      });
      setResumo((prev) => ({
        ...prev,
        ...payload,
        ...data,
        total: !!payload?.group ? data?.total : 1,
      }));
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postGrid = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Grid/Manutencao', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <GridContext.Provider
      value={{
        configGrid,
        configLoading,
        deleteGrid,
        deleteLoading,
        exportPdf,
        exportXls,
        resumo,
        getResumo,
        getLoading,
        postGrid,
        postLoading,
      }}
    >
      {children}
    </GridContext.Provider>
  );
};
