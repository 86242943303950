import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Icon,
} from '@mui/material';
import {
  Settings,
  ChevronLeftRounded,
  Apps,
  Print,
  MoreHoriz,
} from '@mui/icons-material';
import styles from './styles';

const Header = ({
  rotina,
  titulo,
  subtitulo,
  colunas,
  outros = [],
  tabelas = [],
  relatorios = [],
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuTabela, setMenuTabela] = useState(null);
  const [menuRelatorio, setMenuRelatorio] = useState(null);
  const [menuOutros, setMenuOutros] = useState(null);

  const Tabelas = () =>
    tabelas
      ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      ?.map(({ route, icon, name }, index) => (
        <MenuItem
          key={index?.toString()}
          disabled={!menuTabela}
          onClick={() => {
            setMenuTabela(null);
            navigate(`/app/Tabelas/${route}`);
          }}
        >
          {icon && (
            <ListItemIcon>
              <Icon>{icon}</Icon>
            </ListItemIcon>
          )}
          {name}
        </MenuItem>
      ));

  const Relatorios = () =>
    relatorios
      ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      ?.map(
        ({ action, icon, name, show = true }, index) =>
          show && (
            <MenuItem
              key={index?.toString()}
              disabled={!menuRelatorio}
              onClick={() => {
                setMenuRelatorio(null);
                if (action) {
                  action();
                }
              }}
            >
              {icon && (
                <ListItemIcon>
                  <Icon>{icon}</Icon>
                </ListItemIcon>
              )}
              {name}
            </MenuItem>
          )
      );

  const Outros = () =>
    outros
      ?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      ?.map(
        ({ action, icon, name, show = true }, index) =>
          show && (
            <MenuItem
              key={index?.toString()}
              disabled={!menuOutros}
              onClick={() => {
                setMenuOutros(null);
                if (action) {
                  action();
                }
              }}
            >
              {icon && (
                <ListItemIcon>
                  <Icon>{icon}</Icon>
                </ListItemIcon>
              )}
              {name}
            </MenuItem>
          )
      );

  return (
    <div style={styles?.container}>
      {location?.pathname !== '/app' && (
        <IconButton
          color="primary"
          aria-label="Voltar"
          size="small"
          onClick={() => navigate(-1)}
          sx={styles?.backButton}
        >
          <ChevronLeftRounded />
        </IconButton>
      )}
      <div style={styles?.titleContainer}>
        <Typography variant="h5" component="div" sx={styles?.title}>
          {titulo || rotina}
        </Typography>
        <Typography variant="subtitle1" component="div">
          {subtitulo}
        </Typography>
      </div>
      {!!relatorios?.length && (
        <IconButton
          color="primary"
          aria-label="Relatórios"
          size="small"
          onClick={({ currentTarget }) => setMenuRelatorio(currentTarget)}
        >
          <Print />
        </IconButton>
      )}
      {!!tabelas?.length && (
        <IconButton
          color="primary"
          aria-label="Tabelas"
          size="small"
          onClick={({ currentTarget }) => setMenuTabela(currentTarget)}
        >
          <Apps />
        </IconButton>
      )}
      {!!colunas && !!rotina && (
        <IconButton
          color="primary"
          aria-label="Configurações da Tela"
          size="small"
          onClick={() =>
            navigate('/app/Configuracoes', {
              state: { rotina, colunas, titulo },
            })
          }
        >
          <Settings />
        </IconButton>
      )}
      {!!outros?.length && (
        <IconButton
          color="primary"
          aria-label="Opções"
          size="small"
          onClick={({ currentTarget }) => setMenuOutros(currentTarget)}
        >
          <MoreHoriz />
        </IconButton>
      )}
      <Menu
        anchorEl={menuTabela}
        open={!!menuTabela}
        onClose={() => setMenuTabela(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Tabelas />
      </Menu>
      <Menu
        anchorEl={menuRelatorio}
        open={!!menuRelatorio}
        onClose={() => setMenuRelatorio(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Relatorios />
      </Menu>
      <Menu
        anchorEl={menuOutros}
        open={!!menuOutros}
        onClose={() => setMenuOutros(null)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Outros />
      </Menu>
    </div>
  );
};

export default Header;
