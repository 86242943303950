const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drop: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  optionContainer: (style) => ({
    ...style,
    top: style.top + 8,
    flexDirection: 'column',
  }),
  optionLabel: {
    width: '100%',
    fontWeight: 'bold',
  },
  optionSubLabel: {
    width: '100%',
  },
};

export default styles;
