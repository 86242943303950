const styles = {
  searchRoot: {
    '&&': {
      paddingBottom: '0px',
      flex: 1,
    },
  },
  searchInput: {
    '&&': {
      padding: '.5em',
    },
  },
  gridItem: {
    '&&': {
      marginBottom: '1em',
      display: 'flex',
      alignItems: 'stretch',
    },
  },
  button: {
    '&&': {
      marginRight: '1em',
    },
  },
};

export default styles;
