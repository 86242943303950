import React from 'react';
import { CircularProgress } from '@mui/material';
import Container from 'components/Container';
import styles from './styles';

const Loader = ({ children }) => {
  return (
    <Container>
      <CircularProgress color="primary" sx={styles?.spinner} />
    </Container>
  );
};

export default Loader;
