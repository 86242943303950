import React, { useContext, useEffect } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Print } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import styles from './styles';

const Relatórios = () => {
  const navigate = useNavigate();
  const { getRelatorios, getURLRelatorio, relatorios, getLoading } =
    useContext(RelatoriosContext);

  useEffect(() => {
    getRelatorios();
  }, []);

  const onPrintRelatorio = (relatorio) => {
    if (Boolean(relatorio?.fields?.length)) {
      navigate('/app/Relatorios/Filtros', { state: relatorio });
    } else {
      getURLRelatorio({ data: { id: relatorio?.id } });
    }
  };

  const RenderItens = ({ relatorios = [] }) =>
    relatorios?.map((r, i) => (
      <Grid item xs={12} key={i?.toString()}>
        <Card>
          <Box sx={styles?.cardContainer}>
            <Box sx={styles?.cardTextContainer}>
              <Typography variant="h6">{r?.nome}</Typography>
              <Typography variant="body1">{r?.descricao}</Typography>
            </Box>
            <Box sx={styles?.cardButtonContainer}>
              <IconButton
                size="small"
                color="primary"
                onClick={() => onPrintRelatorio(r)}
              >
                <Print />
              </IconButton>
            </Box>
          </Box>
        </Card>
      </Grid>
    ));

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Relatórios" />
      <Grid container spacing={2}>
        {!Boolean(relatorios?.length && !getLoading) && (
          <Grid item xs={12} textAlign="center">
            <Typography variant="body1">Nenhum relatório disponível</Typography>
          </Grid>
        )}
        <RenderItens relatorios={relatorios} />
      </Grid>
    </Container>
  );
};

export default Relatórios;
