const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  divider: {
    my: 2,
  },
  icon: {
    fontSize: 20,
  },
  iconButton: {
    p: 0,
    mr: 1,
  },
};

export default styles;
