import { createContext, useState } from 'react';
import api from 'services/api';

export const ApuracaoContext = createContext();

export const ApuracaoProvider = ({ children }) => {
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const getApuracoes = async ({ params, cb = null }) => {
    try {
      setGetLoading(true);
      const { data } = await api.get(`/Fiscal/Apuracao`, { params });
      if (cb) {
        cb(data);
      }
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postApuracao = async ({ data, cb = null }) => {
    try {
      setPostLoading(true);
      await api.post(`/Fiscal/Apuracao`, data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  return (
    <ApuracaoContext.Provider
      value={{ getApuracoes, getLoading, postLoading, postApuracao }}
    >
      {children}
    </ApuracaoContext.Provider>
  );
};
