import React, { useContext, useState } from 'react';
import { Paper, Grid, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Navigate, useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'contexts/AuthContext';
import Input from 'components/Input';
import Button from 'components/Button';
import Loading from 'views/Loading';
import logo from 'assets/logo.png';
import styles from './styles';

const Login = () => {
  const { state } = useLocation();
  const { user, loginLoading, login } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const refresh_token = localStorage.getItem('ESolution:refreshToken');

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: process.env.REACT_APP_DEV_EMAIL || '',
      password: process.env.REACT_APP_DEV_SENHA || '',
    },
  });

  const onSubmit = (event) => {
    if (event && event?.key === 'Enter') {
      handleSubmit(login)();
    }
  };

  if (user) {
    const from = state?.from || '/app';

    return <Navigate to={from} replace />;
  }
  if (refresh_token) {
    return <Loading />;
  }

  return (
    <div style={styles.container}>
      <div style={styles.insideContainer}>
        <Paper sx={styles.paper} elevation={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item textAlign="center">
              <img src={logo} style={styles.img} alt="Logo" />
            </Grid>
            <Grid item>
              <Input
                name="email"
                control={control}
                label="E-mail"
                type="email"
                onKeyPress={onSubmit}
              />
            </Grid>
            <Grid item>
              <Input
                name="password"
                control={control}
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                onKeyPress={onSubmit}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item textAlign="end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit(login)}
                loading={loginLoading}
              >
                ENTRAR
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default Login;
