import { red } from '@mui/material/colors';

const defaultTheme = {
  palette: {
    primary: {
      light: '#28518A',
      main: '#22267B',
      dark: '#01005E',
      white: '#FFF',
    },
    secondary: {
      light: red[300],
      main: red[500],
      dark: red[700],
      white: '#FFF',
    },
    grey: {
      1000: '#1D1D1D',
    },
  },
};

export { defaultTheme };
