import { DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import InputMask from 'components/InputMask';

const EditModal = ({ title, onSubmit }) => {
  const { closeModal } = useModal();
  const { control, handleSubmit } = useForm({ defaultValues: { valor: '' } });

  return (
    <>
      <DialogTitle>Adicionar {title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <InputMask name="valor" control={control} label="Valor" />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit(onSubmit)}
          color="primary"
          variant="contained"
        >
          Confirmar
        </Button>
        <Button onClick={closeModal} color="secondary" variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};
export default EditModal;
