const styles = {
  toolbar: {
    m: '0 24px',
  },
  menuMobile: {
    flexGrow: 1,
    display: { xs: 'flex', lg: 'none' },
  },
  menuWide: {
    flexGrow: 1,
    display: { xs: 'none', lg: 'flex' },
  },
  menuWideButton: {
    color: 'white',
  },
  menuWideButtonContainer: {
    m: 2,
  },
  avatarWide: {
    flexGrow: 0,
    display: { xs: 'none', lg: 'flex' },
    pl: '1em',
  },
  notificationContainer: {
    flexGrow: 0,
    display: 'flex',
  },
  icon: {
    color: 'primary.white',
  },
  iconList: {
    justifyContent: 'end',
  },
  textList: {
    paddingLeft: '16px',
  },
  menuSettings: {
    mt: '45px',
  },
  menuSettingsAnchor: {
    vertical: 'top',
    horizontal: 'right',
  },
  menuSettingsOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  menuMobileDrawer: {
    sx: {
      bgcolor: 'primary.main',
      color: 'primary.white',
      width: { xs: '75%', sm: '50%' },
      display: 'flex',
    },
  },
  tooltip: {
    whiteSpace: 'pre-line',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  logo: {
    width: '100%',
    maxHeight: 50,
    maxWidth: 200,
  },
};

export default styles;
