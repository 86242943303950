import React, { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Mask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';
import Input from 'components/Input';
import moment from 'moment';

const InputMask = forwardRef(
  ({ control, name, label, type, mask, ...props }, ref) => {
    if (type === 'date') {
      return (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <DatePicker
              {...props}
              name={name}
              label={label}
              value={field?.value}
              onChange={(newValue) => {
                if (newValue) {
                  field?.onChange(moment(newValue).format('YYYY-MM-DD'));
                } else {
                  field?.onChange(null);
                }
              }}
              renderInput={(params) => (
                <Input
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: '',
                  }}
                />
              )}
            />
          )}
        />
      );
    }
    if (type === 'datetime') {
      return (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <DateTimePicker
              {...props}
              name={name}
              label={label}
              value={field?.value}
              onChange={(newValue) => {
                if (newValue) {
                  field?.onChange(moment(newValue).format());
                } else {
                  field?.onChange(null);
                }
              }}
              renderInput={(params) => (
                <Input
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: '',
                  }}
                />
              )}
            />
          )}
        />
      );
    }
    if (mask) {
      return (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Mask
              {...props}
              mask={mask}
              label={label}
              value={field?.value}
              onChange={field?.onChange}
              maskChar={null}
            >
              {(params) => <Input {...params} />}
            </Mask>
          )}
        />
      );
    }
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <NumericFormat
            {...props}
            customInput={Input}
            label={label}
            onValueChange={(values) => {
              if (!!props?.format) {
                field?.onChange(values?.formattedValue || '');
              } else {
                field?.onChange(values?.floatValue || '');
              }
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
            value={field?.value}
          />
        )}
      />
    );
  }
);

export default InputMask;
