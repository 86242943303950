import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { DataGrid } from '@mui/x-data-grid';
import { GridContext } from 'contexts/GridContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';
import columns from './grid';
import styles from './styles';

const Configurações = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const { configGrid, configLoading } = useContext(GridContext);

  useEffect(() => {
    if (state) {
      setRows(state?.colunas?.filter((c) => !!c?.maintainable));
    } else {
      navigate(-1);
    }
  }, []);

  const onSave = () => {
    const colunas = [
      ...rows,
      ...state?.colunas?.filter((c) => !c?.maintainable),
    ];

    configGrid({ rotina: state?.rotina, colunas, cb: () => navigate(-1) });
  };

  const onRevert = () =>
    setRows(state?.colunas?.filter((c) => !!c?.maintainable));

  const onReset = () =>
    configGrid({ rotina: state?.rotina, cb: () => navigate(-1) });

  const onCellEditCommit = (e) => {
    const editedRows = rows?.map((c) => {
      if (c?.field === e?.id) {
        return { ...c, [e?.field]: e?.value };
      } else {
        return { ...c };
      }
    });
    setRows(editedRows);
  };

  const onMove = (params, way) => {
    const from = rows?.findIndex((r) => r?.field === params?.id);
    const to = way === 'up' ? from - 1 : from + 1;
    const cond =
      (way === 'up' && from > 0) || (way === 'down' && from < rows?.length - 1);

    if (cond) {
      setRows((prevState) => {
        const nextState = [...prevState];
        nextState.splice(from, 1);
        nextState.splice(to, 0, params?.row);
        return nextState;
      });
    }
  };

  return (
    <Container>
      <Header titulo="Configurações" />
      <Card title={state?.titulo || state?.rotina}>
        <div style={styles.toolbar}>
          <Button
            size="small"
            aria-label="Redefinir"
            color="secondary"
            variant="outlined"
            sx={styles?.button}
            onClick={onReset}
            loading={configLoading}
          >
            REDEFINIR PADRÃO
          </Button>
          <Button
            size="small"
            aria-label="Reverter"
            color="secondary"
            variant="contained"
            sx={styles?.button}
            onClick={onRevert}
          >
            REVERTER
          </Button>
          <Button
            size="small"
            aria-label="Salvar"
            variant="contained"
            onClick={onSave}
            loading={configLoading}
          >
            SALVAR
          </Button>
        </div>
        <DataGrid
          rows={rows}
          columns={columns({ onMove })}
          hideFooter
          getRowId={(row) => row?.field}
          autoHeight
          density="compact"
          disableSelectionOnClick
          disableColumnMenu
          showCellRightBorder
          showColumnRightBorder
          localeText={{ noRowsLabel: 'Nenhum Registro.' }}
          onCellEditCommit={onCellEditCommit}
        />
      </Card>
    </Container>
  );
};

export default Configurações;
