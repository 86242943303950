import { AuthProvider } from 'contexts/AuthContext';
import { NotificaçõesProvider } from 'contexts/NotificaçõesContext';
import { GridProvider } from 'contexts/GridContext';
import { DropsProvider } from 'contexts/DropsContext';
import { RelatoriosProvider } from 'contexts/RelatoriosContext';
import { DashboardProvider } from 'contexts/DashboardContext';
import { ApuracaoProvider } from 'contexts/ApuraçãoContext';
import {
  DialogProvider,
  ModalProvider,
  DrawerProvider,
} from 'components/Modals';

const Providers = ({ children }) => (
  <AuthProvider>
    <NotificaçõesProvider>
      <GridProvider>
        <DropsProvider>
          <RelatoriosProvider>
            <DashboardProvider>
              <ApuracaoProvider>
                <ModalProvider>
                  <DialogProvider>
                    <DrawerProvider>{children}</DrawerProvider>
                  </DialogProvider>
                </ModalProvider>
              </ApuracaoProvider>
            </DashboardProvider>
          </RelatoriosProvider>
        </DropsProvider>
      </GridProvider>
    </NotificaçõesProvider>
  </AuthProvider>
);

export default Providers;
