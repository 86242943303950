import React, { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { DropsContext } from 'contexts/DropsContext';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';

const renderDefaultValues = (arr = []) => {
  const defaultValues = {};
  arr.map((item) => {
    switch (item?.type) {
      case 'date':
        defaultValues[item?.field] = null;
        break;
      case 'drop':
        defaultValues[item?.field] = null;
        break;
      default:
        defaultValues[item?.field] = '';
        break;
    }
  });
  return defaultValues;
};

const Filtros = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const defaultValues = renderDefaultValues(state?.fields);
  const { control, handleSubmit } = useForm({ defaultValues });
  const { drops } = useContext(DropsContext);
  const { getURLRelatorio, postLoading } = useContext(RelatoriosContext);

  useEffect(() => {
    if (!Boolean(state)) {
      navigate(-1);
    }
  }, [state]);

  const onSubmit = (values) => {
    const data = { id: state?.id, ...values };
    getURLRelatorio({
      data,
      cb: () => navigate(-1),
    });
  };

  return (
    <Container>
      <Header titulo="Filtros" subtitulo={state?.nome} />
      <Card>
        <Grid container spacing={2}>
          {Object?.keys(defaultValues)?.map((m, i) => {
            const input = state?.fields?.find((f) => f?.field === m);
            const field = input?.field || '';
            const label = input?.label || '';
            const options = input?.optionsEval
              ? eval(input?.optionsEval)
              : typeof input?.options === 'string'
              ? drops[input?.options]
              : input?.options;

            const setRender = () => {
              switch (input?.type) {
                case 'date':
                  return (
                    <InputMask
                      name={field}
                      control={control}
                      label={label}
                      type="date"
                    />
                  );
                case 'number':
                  return (
                    <InputMask name={field} control={control} label={label} />
                  );
                case 'string':
                  return <Input name={field} control={control} label={label} />;
                case 'drop':
                  return (
                    <VirtualDrop
                      name={field}
                      control={control}
                      label={label}
                      options={options}
                    />
                  );
                default:
                  return null;
              }
            };
            return (
              <Grid item xs={12} sm={6} key={i?.toString()}>
                {setRender()}
              </Grid>
            );
          })}
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Gerar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Filtros;
