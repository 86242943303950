const styles = {
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardTextContainer: {
    flexGrow: 1,
  },
  cardButtonContainer: {
    padding: '0 0 0 1em',
  },
};

export default styles;
