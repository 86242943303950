const styles = {
  drawerPaper: {
    width: { xs: '100%', md: '75%', lg: '40%' },
    backgroundColor: 'primary.white',
    display: 'flex',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '1em',
  },
  filterContainer: {
    margin: '1em',
  },
  filterIcon: {
    margin: '0 .5em 0 0',
  },
};

export default styles;
