import React from 'react';
import { Paper, Grid, LinearProgress, Typography } from '@mui/material';
import logo from 'assets/logo.png';
import styles from './styles';

const Loading = () => {
  return (
    <div style={styles.container}>
      <div style={styles.insideContainer}>
        <Paper sx={styles.paper} elevation={3}>
          <Grid container direction="column" spacing={2}>
            <Grid item textAlign="center">
              <img src={logo} style={styles.img} alt="Logo" />
            </Grid>
            <Grid item textAlign="center">
              <Typography variant="h6">Iniciando Sessão</Typography>
            </Grid>
            <Grid item>
              <LinearProgress />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default Loading;
