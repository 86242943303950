import axios from 'axios';
import { toastError, toastSuccess } from 'utils/toast';

const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL ||
    'https://esolution-api.azurewebsites.net/api',
  headers: {
    'Accept-Language': 'pt',
  },
});

api.interceptors.request.use((cfg) => {
  const token = localStorage.getItem('ESolution:accessToken');
  if (token) {
    cfg.headers.Authorization = `Bearer ${token}`;
  }
  return cfg;
});

api.interceptors.response.use(
  (res) => {
    if (res?.status !== 200) {
      toastSuccess(res);
    }
    return res;
  },
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 401 && !prevRequest?.sent) {
      prevRequest.sent = true;
      const refresh_token = localStorage.getItem('ESolution:refreshToken');
      const { data } = await api.post('/Auth/Refresh', { refresh_token });

      localStorage.setItem('ESolution:accessToken', data.access_token);
      localStorage.setItem('ESolution:refreshToken', data.refresh_token);

      return api(prevRequest);
    }
    if (error?.response?.status !== 401 && error?.response?.status !== 403) {
      toastError(error);
    }
    if (error?.response?.status === 403) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
