import React, { forwardRef, useContext } from 'react';
import {
  Autocomplete,
  Box,
  IconButton,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { DropsContext } from 'contexts/DropsContext';
import Input from 'components/Input';
import styles from './styles';

const Dropdown = forwardRef(
  (
    { control, name, options = [], label, onValueChange, refresh, ...props },
    ref
  ) => {
    const { getDrops } = useContext(DropsContext);

    const onRefresh = () => getDrops([refresh]);

    const filterOptions = createFilterOptions({
      stringify: (option) =>
        Object?.keys(option)
          ?.filter(
            (f) =>
              f?.substring(0, 1) === f?.substring(0, 1)?.toUpperCase() ||
              f === 'label'
          )
          ?.map((key) => option[key])
          ?.join(''),
    });

    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Box sx={styles?.container}>
            <Autocomplete
              {...props}
              clearOnEscape
              sx={{ ...styles?.drop, ...props?.sx }}
              options={options || []}
              value={field?.value}
              filterOptions={filterOptions}
              renderInput={(params) => (
                <Input
                  {...params}
                  {...field}
                  label={label}
                  onChange={() => {}}
                />
              )}
              renderOption={(props, option) => {
                return (
                  <Box
                    {...props}
                    key={option.value}
                    sx={styles?.optionContainer}
                  >
                    <Typography sx={styles?.optionLabel} variant="body1">
                      {option.label}
                    </Typography>
                    {Object?.keys(option)
                      ?.filter(
                        (f) =>
                          f?.substring(0, 1) ===
                          f?.substring(0, 1)?.toUpperCase()
                      )
                      ?.map((o, i) => (
                        <Typography
                          key={i?.toString()}
                          sx={styles?.optionSubLabel}
                          variant="body2"
                        >
                          {o}: {option[o]}
                        </Typography>
                      ))}
                  </Box>
                );
              }}
              onChange={(_, data) => {
                if (!!props?.multiple) {
                  field?.onChange(data?.map((d) => d.value || d));
                  if (!!onValueChange) {
                    onValueChange(data?.map((d) => d.value || d));
                  }
                } else {
                  field?.onChange(data?.value || null);
                  if (!!onValueChange) {
                    onValueChange(data || null);
                  }
                }
              }}
              getOptionLabel={(option) => {
                const ret = options?.find((c) => c.value === option);
                return option?.label || ret?.label || '';
              }}
              isOptionEqualToValue={(option, value) => option.value === value}
            />
            {Boolean(refresh) && (
              <IconButton size="small" onClick={onRefresh}>
                <Refresh fontSize="small" />
              </IconButton>
            )}
          </Box>
        )}
      />
    );
  }
);

export default Dropdown;
