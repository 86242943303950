import { createContext, useContext, useState, forwardRef } from 'react';
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Drawer,
} from '@mui/material';
import { Close } from '@mui/icons-material';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export const DialogContext = createContext();

export const useDialog = () => useContext(DialogContext);

export const DialogProvider = ({ children }) => {
  const [dialog, setDialog] = useState({
    open: false,
    content: null,
  });

  const styles = {
    container: {
      padding: '24px',
    },
  };

  const openDialog = (content, title) =>
    setDialog({
      open: true,
      content,
      title,
    });

  const closeDialog = () => setDialog({ open: false, content: null });

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      <Dialog
        fullScreen
        open={dialog?.open}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <AppBar position="relative">
          <Toolbar disableGutters sx={{ margin: '0 24px' }}>
            <IconButton color="inherit" onClick={closeDialog}>
              <Close />
            </IconButton>
            {!!dialog?.title && (
              <Typography sx={{ ml: 2 }} variant="h6">
                {dialog?.title}
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <div style={styles.container}>{dialog?.content}</div>
      </Dialog>
      {children}
    </DialogContext.Provider>
  );
};

export const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({
    open: false,
    content: null,
  });

  const openModal = (content, size) =>
    setModal({
      open: true,
      content,
      size,
    });

  const closeModal = () => setModal({ open: false, content: null });

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Dialog
        open={modal?.open}
        onClose={closeModal}
        TransitionComponent={Transition}
        PaperProps={{ sx: { minWidth: `${modal?.size || 50}%` } }}
      >
        {modal?.content}
      </Dialog>
      {children}
    </ModalContext.Provider>
  );
};

export const DrawerContext = createContext();

export const useDrawer = () => useContext(DrawerContext);

export const DrawerProvider = ({ children }) => {
  const [drawer, setDrawer] = useState({
    open: false,
    content: null,
  });

  const styles = {
    drawerPaper: {
      width: { xs: '100%', sm: '75%', md: '50%', lg: '30%' },
      backgroundColor: 'primary.white',
      display: 'flex',
    },
    container: {
      padding: '1em',
    },
  };

  const openDrawer = (content, title) =>
    setDrawer({
      open: true,
      content,
      title,
    });

  const closeDrawer = () => setDrawer({ open: false, content: null });

  return (
    <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
      <Drawer
        open={drawer?.open}
        variant="temporary"
        anchor="right"
        onClose={closeDrawer}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: styles?.drawerPaper }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={styles?.container}
        >
          <Typography variant="h5" component="h1">
            {drawer?.title}
          </Typography>
          <IconButton size="small" aria-label="Fechar" onClick={closeDrawer}>
            <Close />
          </IconButton>
        </Box>
        <Box>{drawer?.content}</Box>
      </Drawer>
      {children}
    </DrawerContext.Provider>
  );
};
