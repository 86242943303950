import React from 'react';
import { Drawer, Typography, IconButton } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Close, FilterAltOff } from '@mui/icons-material';
import Button from 'components/Button';
import styles from './styles';

const GridFilter = ({
  handleDrawerToggle,
  onSubmit,
  onReset,
  children,
  ...props
}) => {
  return (
    <Drawer
      {...props}
      variant="temporary"
      anchor="right"
      onClose={handleDrawerToggle}
      ModalProps={{ keepMounted: true }}
      PaperProps={{ sx: styles?.drawerPaper }}
    >
      <div style={styles?.header}>
        <Typography
          variant="h5"
          component="h1"
        >
          Filtros
        </Typography>
        <div>
          <IconButton
            size="small"
            aria-label="Limpar"
            onClick={onReset}
            sx={styles.filterIcon}
          >
            <FilterAltOff />
          </IconButton>
          <IconButton
            size="small"
            aria-label="Fechar"
            onClick={handleDrawerToggle}
          >
            <Close />
          </IconButton>
        </div>
      </div>
      <Scrollbars>
        <div style={styles?.filterContainer}>{children}</div>
      </Scrollbars>
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        style={{ borderRadius: '0' }}
      >
        FILTRAR
      </Button>
    </Drawer>
  );
};

export default GridFilter;
