import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'contexts/AuthContext';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Header from 'components/Header';
import Button from 'components/Button';
import Input from 'components/Input';
import Card from 'components/Card';

const User = () => {
  const titulo = 'Dados Cadastrais';
  const { user, postLoading, postUser } = useContext(AuthContext);
  const defaultValues = {
    id: user?.id,
    name: user?.name,
    email: user?.email,
    empresa_id: user?.empresa_id,
    password: '',
    new_password: '',
    confirm_new_password: '',
  };
  const { control, handleSubmit } = useForm({ defaultValues });

  const dropEmpresas = (user?.Empresas || [])?.map((e) => ({
    value: e?.id,
    label: e?.nome,
  }));

  const onSubmit = (data) =>
    postUser({ data, cb: () => window.location.reload() });

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        <Grid item xs={6} display="flex">
          <Card title="Usuário" style={{ flex: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input name="name" control={control} label="Nome" />
              </Grid>
              <Grid item xs={12}>
                <Input name="email" control={control} label="E-mail" />
              </Grid>
              <Grid item xs={12}>
                <Dropdown
                  name="empresa_id"
                  control={control}
                  label="Empresa"
                  options={dropEmpresas}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card title="Senha">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  name="password"
                  control={control}
                  label="Senha atual"
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="new_password"
                  control={control}
                  label="Nova senha"
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  name="confirm_new_password"
                  control={control}
                  label="Confirmar nova senha"
                  type="password"
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            loading={postLoading}
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default User;
