import React, { useContext } from 'react';
import { useLocation } from 'react-router';
import { GridContext } from 'contexts/GridContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';

const Resumo = () => {
  const { state } = useLocation();
  const { exportPdf, exportXls, getLoading, getResumo, resumo } =
    useContext(GridContext);

  const loadGrid = (props) => getResumo({ ...state, ...props, total: true });

  const options = [
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () => exportPdf({ ...state, total: true, order: resumo?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () => exportXls({ ...state, total: true, order: resumo?.order }),
    },
  ];

  return (
    <Container>
      <Header titulo="Resumo" />
      <Card>
        <Grid
          grid={resumo}
          options={options}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          getRowId={(row) => JSON.stringify(row)}
        />
      </Card>
    </Container>
  );
};

export default Resumo;
