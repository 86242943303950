import React, { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { DashboardContext } from 'contexts/DashboardContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Loader from 'components/Loader';
import Card from 'components/Card';
import styles from './styles';

const App = () => {
  const titulo = 'Dashboard';
  const { dashData, getLoading, getDashboard } = useContext(DashboardContext);

  useEffect(() => {
    getDashboard();
  }, []);

  if (getLoading) {
    return <Loader />;
  }

  const formatHeader = (item) =>
    Boolean(item?.length) ? item?.map((g) => g?.label) : [''];

  const formatBody = (item, key) =>
    Boolean(item?.length) ? item?.map((g) => g[key]) : [0];

  const currency = (value) =>
    (value || 0)?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });

  return (
    <Container>
      <Header titulo={titulo} />
      <Grid container spacing={2}>
        {Object.keys(dashData || {})?.map((m) => {
          const item = dashData[m];

          const markup = (_, { dataIndex }) =>
            `${(item[dataIndex]?.markup || 0)?.toFixed(2)}%`;
          const diff = (value, { dataIndex }) =>
            `${(value || 0)?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })} (${(item[dataIndex]?.diferenca_pc || 0)?.toFixed(2)}%)`;

          return (
            <Grid key={m} item xs={12} sm={6} display="flex">
              <Card title={m} style={styles?.card}>
                <BarChart
                  slotProps={{ legend: { hidden: true } }}
                  xAxis={[{ data: formatHeader(item), scaleType: 'band' }]}
                  series={[
                    {
                      stack: 'A',
                      label: 'Compra',
                      data: formatBody(item, 'compra'),
                      valueFormatter: currency,
                      color: '#011f4b',
                    },
                    {
                      stack: 'A',
                      label: 'Compra Tributada',
                      data: formatBody(item, 'compra_tributada'),
                      valueFormatter: currency,
                      color: '#005b96',
                    },
                    {
                      stack: 'A',
                      label: 'Crédito',
                      data: formatBody(item, 'credito'),
                      valueFormatter: currency,
                      color: '#b3cde0',
                    },
                    {
                      stack: 'B',
                      label: 'Venda',
                      data: formatBody(item, 'venda'),
                      valueFormatter: currency,
                      color: '#073B3A',
                    },
                    {
                      stack: 'B',
                      label: 'Venda Tributada',
                      data: formatBody(item, 'venda_tributada'),
                      valueFormatter: currency,
                      color: '#08A045',
                    },
                    {
                      stack: 'B',
                      label: 'Débito',
                      data: formatBody(item, 'debito'),
                      valueFormatter: currency,
                      color: '#21D375',
                    },
                    {
                      stack: 'A',
                      label: 'Markup',
                      data: [0],
                      valueFormatter: markup,
                      color: 'transparent',
                    },
                    {
                      stack: 'B',
                      label: 'Diferença',
                      data: [0],
                      valueFormatter: diff,
                      color: 'transparent',
                    },
                  ]}
                  skipAnimation
                  height={300}
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default App;
