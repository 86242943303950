import React from 'react';
import { Paper, Typography } from '@mui/material';
import styles from './styles';

const Card = ({ title, style, children, ...props }) => {
  return (
    <Paper {...props} sx={{ ...styles.container, ...style }} elevation={3}>
      {title && (
        <Typography
          variant="h6"
          component="div"
          align="center"
          sx={styles.title}
        >
          {title}
        </Typography>
      )}
      {children}
    </Paper>
  );
};

export default Card;
