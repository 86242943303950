import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ToastContainer } from 'react-toastify';
import { defaultTheme } from 'utils/theme';
import { AuthContext } from 'contexts/AuthContext';
import Providers from 'contexts';
import App from 'views';
import Error from 'views/Error';
import Login from 'views/Login';

import 'moment/locale/pt-br';
import 'react-toastify/dist/ReactToastify.css';

const ProtectedRoutes = () => {
  const from = useLocation();
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return <App />;
  }

  return <Navigate to="/" state={{ from }} replace />;
};

const Root = () => {
  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <BrowserRouter>
          <Providers>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/app/*" element={<ProtectedRoutes />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </Providers>
        </BrowserRouter>
        <ToastContainer />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
