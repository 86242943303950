import React, { forwardRef } from 'react';
import { Button, CircularProgress } from '@mui/material';

const CustomButton = forwardRef(
  ({ loading, onClick, children, ...props }, ref) => {
    return (
      <Button
        size="small"
        {...props}
        ref={ref}
        onClick={(e) => {
          if (!loading) {
            onClick(e);
          }
        }}
      >
        {children}
        {loading && (
          <CircularProgress size={20} color="inherit" sx={{ ml: '16px' }} />
        )}
      </Button>
    );
  }
);

export default CustomButton;
