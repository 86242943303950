const styles = {
  toolbar: {
    margin: '1em 0',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  button: {
    '&&': {
      marginRight: '1em',
    },
  },
};

export default styles;
