import { createContext, useState } from 'react';
import api from 'services/api';

export const DropsContext = createContext();

export const DropsProvider = ({ children }) => {
  const [drops, setDrops] = useState({});
  const [dropLoading, setDropLoading] = useState(false);

  const getDrops = async (models = []) => {
    try {
      setDropLoading(true);
      models?.map(async (m) => {
        const { data } = await api.get(`/Drops/${m}`);
        setDrops((prev) => ({ ...prev, [m]: data }));
      });
    } catch (error) {
    } finally {
      setDropLoading(false);
    }
  };

  return (
    <DropsContext.Provider value={{ getDrops, drops, dropLoading }}>
      {children}
    </DropsContext.Provider>
  );
};
