import React, { useContext, useState } from 'react';
import {
  Box,
  ButtonGroup,
  Checkbox,
  Divider,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  Delete,
  DoneAll,
  FiberManualRecord,
  MoreVert,
} from '@mui/icons-material';
import { NotificaçõesContext } from 'contexts/NotificaçõesContext';
import Button from 'components/Button';
import moment from 'moment';
import styles from './styles';

const NotificacaoModal = () => {
  const [checked, setChecked] = useState([]);
  const {
    getLoading,
    postLoading,
    notifications,
    getNotifications,
    postNotifications,
  } = useContext(NotificaçõesContext);
  const loading = Boolean(getLoading || postLoading);
  const [target, setTarget] = useState(null);
  const options = (n) => [
    {
      action: () => onDownload(n?.midia_url),
      icon: 'download',
      name: 'Download',
      show: Boolean(n?.midia_url),
    },
    {
      action: () => onUpdate({ id: n?.id }),
      icon: 'delete',
      name: 'Excluir',
    },
    {
      action: () => onUpdate({ id: n?.id, finalizado: 'SIM' }),
      icon: 'done_all',
      name: 'Marcar como lida',
      show: Boolean(n?.finalizado === 'NAO'),
    },
    {
      action: () => onUpdate({ id: n?.id, finalizado: 'NAO' }),
      icon: 'remove_done',
      name: 'Marcar como não lida',
      show: Boolean(n?.finalizado === 'SIM'),
    },
  ];

  const reload = () => {
    setChecked([]);
    getNotifications();
  };

  const onDownload = (url) => window.open(url);

  const onUpdate = (data) => {
    postNotifications({ data, cb: reload });
  };

  const onUpdateAll = (params = {}) => {
    const ids = Boolean(checked?.length)
      ? checked
      : notifications?.map((n) => n?.id);
    const data = { ids, ...params };
    postNotifications({ data, cb: reload });
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked?.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked?.push(value);
    } else {
      newChecked?.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Grid container spacing={1}>
      {!Boolean(notifications?.length) && (
        <Grid item xs={12} textAlign="center">
          <Typography variant="caption">
            Você não possui nenhuma notificação
          </Typography>
        </Grid>
      )}
      {Boolean(notifications?.length) && (
        <Grid item xs={12} textAlign="center">
          <ButtonGroup variant="outlined" color="inherit" disabled={loading}>
            <Button startIcon={<Delete />} onClick={() => onUpdateAll()}>
              Excluir {Boolean(checked?.length) ? 'Selecionadas' : 'Todas'}
            </Button>
            <Button
              startIcon={<DoneAll />}
              onClick={() => onUpdateAll({ finalizado: 'SIM' })}
            >
              Ler {Boolean(checked?.length) ? 'Selecionadas' : 'Todas'}
            </Button>
          </ButtonGroup>
        </Grid>
      )}
      {Boolean(notifications?.length) && (
        <Grid item xs={12}>
          {loading && <LinearProgress />}
          <List>
            {notifications?.map((n, index) => {
              return (
                <Box key={index?.toString()}>
                  {!Boolean(index) && <Divider />}
                  <ListItem
                    disablePadding
                    secondaryAction={
                      <IconButton
                        size="small"
                        disabled={loading}
                        onClick={({ currentTarget }) =>
                          setTarget({ currentTarget, index })
                        }
                      >
                        <MoreVert fontSize="small" />
                      </IconButton>
                    }
                  >
                    <ListItemButton
                      dense
                      disabled={loading}
                      onClick={handleToggle(n?.id)}
                    >
                      <ListItemIcon sx={styles?.dotContainer}>
                        <FiberManualRecord sx={styles?.dot(n)} />
                        <Checkbox
                          edge="start"
                          disableRipple
                          checked={checked?.indexOf(n?.id) !== -1}
                        />
                      </ListItemIcon>
                      <Box flex={1} flexDirection="column" display="flex">
                        <Typography variant="button" whiteSpace="pre-wrap">
                          {n?.notificacao}
                        </Typography>
                        {Boolean(n?.inicioproc) &&
                          moment(n?.inicioproc).isValid() && (
                            <Typography variant="caption">
                              {moment(n?.inicioproc).format(
                                'DD/MM/YYYY [às] HH:mm'
                              )}
                            </Typography>
                          )}
                      </Box>
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                  <Menu
                    anchorEl={target?.currentTarget}
                    open={Boolean(target && target?.index === index)}
                    onClose={() => setTarget(null)}
                  >
                    {options(n)?.map(
                      ({ action, icon, name, show = true }, index) =>
                        show && (
                          <MenuItem
                            key={index?.toString()}
                            disabled={!Boolean(target) || loading}
                            onClick={() => {
                              setTarget(null);
                              if (action) action(n);
                            }}
                          >
                            {icon && (
                              <ListItemIcon>
                                <Icon>{icon}</Icon>
                              </ListItemIcon>
                            )}
                            {name}
                          </MenuItem>
                        )
                    )}
                  </Menu>
                </Box>
              );
            })}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default NotificacaoModal;
