export default {
  // Root
  noRowsLabel: 'Nenhum Registro.',
  noResultsOverlayLabel: 'Nenhum registro encontrado.',
  errorOverlayDefaultLabel: 'Occoreu um erro.',

  // Columns selector toolbar button text
  toolbarColumns: 'Colunas',
  toolbarColumnsLabel: 'Selecionar colunas',

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'Buscar...',
  toolbarQuickFilterLabel: 'Buscar',
  toolbarQuickFilterDeleteIconLabel: 'Limpar',

  // Export selector toolbar button text
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Download CSV',
  toolbarExportPrint: 'Print',
  toolbarExportExcel: 'Download Excel',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Procurar coluna',
  columnsPanelTextFieldPlaceholder: 'Título',
  columnsPanelDragIconLabel: 'Reordenar coluna',
  columnsPanelShowAllButton: 'Mostrar tudo',
  columnsPanelHideAllButton: 'Esconder Tudo',

  // Column header text
  columnHeaderSortIconLabel: 'Ordenar',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} linhas selecionadas`
      : `${count.toLocaleString()} linha selecionada`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Marcador',
  checkboxSelectionSelectAllRows: 'Selecionar todas as linhas',
  checkboxSelectionUnselectAllRows: 'Remover todas as linhas',
  checkboxSelectionSelectRow: 'Selecionar linha',
  checkboxSelectionUnselectRow: 'Remover linha',

  // Boolean cell text
  booleanCellTrueLabel: 'Sim',
  booleanCellFalseLabel: 'Não',

  // Actions cell more text
  actionsCellMore: 'Mais',
};
