const styles = {
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionCell: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
};

export default styles;
